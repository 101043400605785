<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <a-card style="width: 96%; margin: 10px auto; margin-bottom: 20px">
      <a-form-model
        ref="form"
        class="ak-query-filter"
        :model="queryFilters"
        :colon="false"
      >
        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="手机号" prop="phone">
              <a-input v-model="queryFilters.phone" placeholder="请输入" />
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="名称" prop="shopName">
              <a-input v-model="queryFilters.shopName" placeholder="请输入" />
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="状态" prop="enableState">
              <ak-select
                :loadData="statusOpts"
                allowClear
                v-model="queryFilters.enableState"
                placeholder="请选择"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="标题" prop="title">
              <a-input v-model="queryFilters.title" placeholder="请输入" />
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="标签" prop="tabName">
              <a-input v-model="queryFilters.tabName" placeholder="请输入" />
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="关联商品" prop="sku">
              <a-input
                v-model="queryFilters.sku"
                placeholder="请输入商品货号或编号"
              />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="发布时间" prop="date">
              <a-range-picker
                v-model="queryFilters.date"
                show-time
                class="W100"
                :placeholder="['请选择', '请选择']"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-model-item>
          </a-col>

          <a-col class="action-btn-main">
            <a-button type="primary" class="ML12" @click="search"
              >查询</a-button
            >
            <a-button class="ML12" @click="onResetForm">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-table
      :pagination="pagination"
      :columns="columns"
      :data-source="tableList"
      :scroll="{ x: 100 }"
      :rowKey="(record) => record.trendId"
      @change="handleTableChange"
      :loading="loading"
      style="width: 96%; margin: 0 auto"
      ref="table"
      id="fixedTable"
    >
      <!-- 用户信息 -->
      <template #personalResp="text">
        <a-row type="flex" align="middle">
          <a-col :span="12">
            <img :src="text.headImage" style="width: 60px" alt="" />
          </a-col>
          <a-col :span="12" style="text-align: right">
            {{ text.name }}<br />{{ text.phone }}
          </a-col>
        </a-row>
      </template>

      <span slot="likes" slot-scope="text, record" class="likes">
        <template>
          <a-row :gutter="20">
            <a-col span="12"
              ><a-icon type="heart" /><span class="text">{{
                record.like
              }}</span></a-col
            >
            <a-col span="12"
              ><a-icon type="star" /><span class="text">{{
                record.favorite
              }}</span></a-col
            >
          </a-row>
          <a-row :gutter="20">
            <a-col span="12"
              ><a-icon type="eye" /><span class="text">{{
                record.browse
              }}</span></a-col
            >
            <a-col span="12"
              ><a-icon type="share-alt" /><span class="text">{{
                record.share
              }}</span></a-col
            >
          </a-row>
        </template>
      </span>

      <!-- 状态 -->
      <template #coverUri="text">
        <viewer :images="[`${$const.OssImagePrefix}/${text}`]">
          <img style="height: 60px" :src="`${$const.OssImagePrefix}/${text}`" />
        </viewer>
      </template>

      <!-- 状态 -->
      <template #enableState="text">
        <span v-if="text === 1">上架</span>
        <span v-if="text === 2">下架</span>
      </template>
      <template #logo="text">
        <viewer :images="[`${$const.OssImagePrefix}/${text}`]">
          <img
            v-if="text"
            style="height: 60px"
            :src="`${$const.OssImagePrefix}/${text}`"
          />
        </viewer>
      </template>
      <template #ctitle="text">
        <div class="textoverflow">{{ text }}</div>
      </template>
      <template #labName="text">
        <div class="textoverflow">{{ text }}</div>
      </template>
      <!-- 操作 -->
      <!-- <template #action="text, record"> -->
      <template #action="text, record">
        <router-link
          v-if="isPictureType === 'pic'"
          :to="`/content/picture-manage/pictureDetail?id=${record.trendIssueId}&type=${businessType}&isPic=${isPictureType}`"
          >详情</router-link
        >
        <router-link
          v-else
          :to="`/content/video-manage/videoDetail?id=${record.trendIssueId}&type=${businessType}&isPic=${isPictureType}`"
          >详情</router-link
        >
        &nbsp;&nbsp;
        <a v-if="record && record.enableState === 1" @click="confirm(record, 2)"
          >下架</a
        >
        <a v-else @click="confirm(record, 1)">上架</a>
        &nbsp;&nbsp;
         <router-link :to="{path:`/content/picture-manage/brushData`, query: record}">
          刷数据
        </router-link>
        
      </template>
    </a-table>
  </ak-container>
</template>
<script>
import * as Content from '@/api/content/video.api'

const columns = [
  {
    title: '封面',
    dataIndex: 'coverUri',
    scopedSlots: { customRender: 'coverUri' },
    width: '200px',
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: '200px',
    scopedSlots: { customRender: 'ctitle' },
  },
  {
    title: '状态',
    dataIndex: 'enableState',
    scopedSlots: { customRender: 'enableState' },
    align: 'right',
    width: '130px',
  },
  {
    title: '标签',
    dataIndex: 'labName',
    align: 'center',
    scopedSlots: { customRender: 'labName' },
    width: 200,
  },
  {
    title: 'logo',
    dataIndex: 'logo',
    scopedSlots: { customRender: 'logo' },
    width: 200,
  },
  {
    title: '名称',
    dataIndex: 'shopName',
    width: 200,
  },
  {
    title: '绑定用户',
    dataIndex: 'personalResp',
    scopedSlots: { customRender: 'personalResp' },
    width: 200,
  },
  {
    title: '喜欢/收藏/预览/分享',
    dataIndex: 'likes',
    scopedSlots: { customRender: 'likes' },
    width: 180,
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.createdTime > b.createdTime ? 1 : -1
    },
    width: 140,
  },
  {
    title: '发布时间',
    dataIndex: 'issueTime',
    defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
    sorter: (a, b) => {
      return a.issueTime > b.issueTime ? 1 : -1
    },
    width: 140,
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    width: '200px',
    fixed: 'right',
  },
]
export default {
  data() {
    return {
      // 查询参数
      queryFilters: {
        phone: '',
        shopName: '',
        enableState: 0,
        title: '',
        tabName: '',
        sku: '',
        issueStartTime: '',
        issueEndTime: '',
        page: 1,
        pageSize: 10,
        date: [],
      },
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        current: 1,
      },
      columns,
      tableList: [],
      loading: false,
      statusOpts: [
        { value: 0, label: '全部' },
        { value: 1, label: '上架' },
        { value: 2, label: '下架' },
      ],
      isPictureType: 'pic',
      contentType: 1,
      businessType: 1,
    }
  },
  created() {
    // this.queryData()
  },
  methods: {
    fixTable() {
      
      let that = this
      that.loading = true
      that.$nextTick(() => {
        //table的id
        let tableId = 'fixedTable'

        const scrollDiv = document.querySelector(
          `#${tableId} .ant-table-scroll > .ant-table-body`
        )
        const leftFixedDiv = document.querySelector(
          `#${tableId} .ant-table-fixed-left .ant-table-body-inner`
        )
        const rightFixedDiv = document.querySelector(
          `#${tableId} .ant-table-fixed-right .ant-table-body-inner`
        )

        //表头thead的tr高度
        const cssHeaderSelector = 'table.ant-table-fixed thead'
        const scrollHeaderTr = scrollDiv.querySelector(cssHeaderSelector)
        // const leftFixedHeaderTr = leftFixedDiv.querySelector(cssHeaderSelector)
        const rightFixedHeaderTr =
          rightFixedDiv.querySelector(cssHeaderSelector)

        const theoryHeaderTrHeight = getComputedStyle(scrollHeaderTr).height

        // leftFixedHeaderTr.style.height = theoryHeaderTrHeight
        rightFixedHeaderTr.style.height = theoryHeaderTrHeight

        //表体tbody的tr高度，循环对每一行进行调整
        this.$nextTick(()=>{
          setTimeout(() => {
          that.tableList.forEach((item) => {
            //每一行的rowKey值，也就是<a-table>设置的rowKey
            let rowKey = item.trendId

            const cssSelector = `table.ant-table-fixed tr[data-row-key='${rowKey}']`
            const rightFixedTr = rightFixedDiv.querySelector(cssSelector)
            // const leftFixedTr = leftFixedDiv.querySelector(cssSelector)
            const scrollTr = scrollDiv.querySelector(cssSelector)

            const theoryTrHeight = getComputedStyle(scrollTr).height
            console.log(theoryTrHeight)

            // leftFixedTr.style.height = theoryTrHeight
            rightFixedTr.style.height = theoryTrHeight

          })
          that.loading = false
        }, 500)
        })
        
      })
    },
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      let that = this
      that.sortedInfo = sorter
      const pager = { ...that.pagination }
      pager.current = pagination.current
      pager.pageSize = pagination.pageSize
      that.pagination = pager
      this.queryFilters.page = pagination.current
      this.queryFilters.pageSize = pagination.pageSize
      that.queryData()
    },
    // 加载数据方法
    queryData() {
      this.loading = true
      let params = {
        phone: this.queryFilters.phone,
        shopName: this.queryFilters.shopName,
        enableState:
          this.queryFilters.enableState === 0
            ? null
            : this.queryFilters.enableState,
        contentType: this.contentType,
        title: this.queryFilters.title,
        tabName: this.queryFilters.tabName,
        sku: this.queryFilters.sku,
        issueStartTime: this.queryFilters.date ? this.queryFilters.date[0] : '',
        issueEndTime: this.queryFilters.date ? this.queryFilters.date[1] : '',
        businessType: this.businessType,
        page: this.queryFilters.page,
        pageSize: this.queryFilters.pageSize,
      }
      Content.search(params)
        .then((res) => {
          if (res.code === 0) {
            this.tableList = res.data.list
            this.pagination.total = res.data.total
          }
          this.$nextTick(()=>{
            this.fixTable()
            this.loading = false
          })
           
          
          
        })
        .catch((e) => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
    confirm(record, state) {
      this.$confirm({
        icon: 'exclamation-circle',
        title: state === 1 ? '确认上架该动态' : '确认下架该动态',
        content: '确认后用户不可看到该动态',
        onOk: () => {
          let param = {
            trendId: record.trendId,
            state: state,
          }
          Content.trend(param)
            .then((res) => {
              this.$message.success('修改成功')
              this.queryData()
            })
            .catch((e) => {
              this.message = e.message || '查询错误'
            })
            .finally(() => {
              this.loading = false
            })
        },
        onCancel: () => {},
        class: 'grounding-modal',
      })
    },
    // 重置
    onResetForm() {
      this.$refs.form.resetFields()
      this.queryFilters = this.$options.data.call(this).queryFilters
      this.queryData()
    },
    tabChange(param) {
      this.contentType = param.contentType
      this.businessType = param.businessType
      this.isPictureType = param.contentType === 1 ? 'pic' : 'video'
      this.search()
    },
    search() {
      this.pagination.current = 1
      this.queryFilters.page = 1
      this.queryData()
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 18px 18px 8px 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
.textoverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}
</style>
