<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <a-tabs v-model="activeKey" @change="tabChange">
      <a-tab-pane key="1" :tab="`创作者平台${titleType}管理`">
      </a-tab-pane>
      <a-tab-pane key="2" :tab="`商家平台${titleType}管理`">
      </a-tab-pane>
      <a-tab-pane key="3" :tab="`独立店${titleType}管理`">
      </a-tab-pane>
    </a-tabs>
    <CommonVideoTable ref="child" />
  </ak-container>
</template>

<script>
import CommonVideoTable from './commonVideoTable.vue'

export default {
  components: {
    CommonVideoTable
  },
  data () {
    return {
      activeKey: '1',
      titleType: ''
    }
  },
  watch: {
    // 监听路由地址的改变
    $route: {
      immediate: true,
      handler(){
        this.titleType = ''
        let param = {
          contentType: 1,
          businessType: 1
        };
        if (this.$route.path.indexOf("picture-manage") > -1){// 需要监听的参数
          this.activeKey = '1'
          this.titleType = '图文'
          param.contentType = 1;
        } else {
          this.activeKey = '1'
          this.titleType = '视频'
          param.contentType = 2;
        }
        if (this.$refs.child) {
          this.$refs.child.tabChange(param);
        }
      }
    }
  },
  mounted() {
    console.log(this.$route.path)
    let param = {
      contentType: 1,
      businessType: 1
    };
    if (this.$route.path.indexOf("picture-manage") > -1){
      this.activeKey = '1'
      this.titleType = '图文'
      param.contentType = 1;
    } else {
      this.activeKey = '1'
      this.titleType = '视频'
      param.contentType = 2;
    }
    this.$refs.child.tabChange(param);
  },
  methods: {
    // 拖动结束，松开鼠标
    onDrop (sourceItem, targetItem, isDrop) {
      console.log(sourceItem, targetItem, isDrop)
    },
    confirm () {
      this.$confirm({
        icon: 'exclamation-circle',
        title: '是否删除该信息？',
        content: '删除该标签将会解除，动态与标签的关系，请谨慎操作',
        onOk: () => {},
        onCancel: () => {},
        class: 'grounding-modal'
      })
    },
    onSearch (searchValue) {
      console.log('use value', searchValue)
    },
    tabChange(e) {
      this.activeKey = e;
      let businessType = [0, 1, 3, 2][e] + '';
      let param = {
        contentType: this.titleType === '图文' ? 1 : 2,
        businessType: Number(businessType)
      };
      console.log(param)
      this.$refs.child.tabChange(param);
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 18px 18px 8px 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}
</style>
